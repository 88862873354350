<template>
  <div id="correlation-view">
    <Loader v-if="isLoading" />
    <div v-show="!isLoading">
      <div style="text-align: right !important; display: flex">
        <vs-button
          type="border"
          icon-pack="feather"
          icon="icon-cloud-lightning"
          @click="onGenerateModelClick"
          :disabled="isProcessing"
          >{{ $l.get("correlation-view.generate-model") }}</vs-button
        >
        <vs-button
          style="margin-left: 5px"
          type="border"
          icon="cached"
          @click="reload"
          >{{ $l.get("dictionary.refresh") }}</vs-button
        >
        <vs-button
          style="margin-left: 5px"
          type="border"
          icon-pack="feather"
          icon="icon-edit"
          @click="onEditClick"
          >{{ $l.get("dictionary.edit") }}</vs-button
        >
      </div>
      <br />
      <vs-card v-if="isControlGroupDefault">
        <div slot="header">
          <h3>{{ $l.get("correlation-view.control-group") }}</h3>
        </div>
        <vs-table v-if="data" :data="[1]">
          <template slot="thead">
            <vs-th>{{ $l.get("correlation-view.related-snps") }}</vs-th>
            <vs-th>
              {{ $l.get("correlation-view.groups-table-users-count") }}
            </vs-th>
          </template>
          <template>
            <vs-tr>
              <vs-td>
                <a :href="getControlResultsDownloadLink()" download>{{
                  data.results.control
                }}</a>
              </vs-td>
              <vs-td>{{ controlGroupUsersCount }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-card>
      <br />
      <vs-card v-if="isControlGroupSplitCheck">
        <div slot="header">
          <h3>{{ $l.get("correlation-view.control-group") }}</h3>
        </div>
        <vs-table v-if="data" :data="[1]">
          <template slot="thead">
            <vs-th>#</vs-th>
            <vs-th>{{ $l.get("correlation-view.related-snps") }}</vs-th>
            <vs-th>
              {{ $l.get("correlation-view.groups-table-users-count") }}
            </vs-th>
          </template>
          <template>
            <vs-tr>
              <vs-td>1</vs-td>
              <vs-td>
                <a :href="getControlResultsDownloadLink()" download>{{
                  getControlResultByGroupId(1)
                }}</a>
              </vs-td>
              <vs-td>{{ controlGroupUsersCountSplitCheck(1) }}</vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td>2</vs-td>
              <vs-td>
                <a :href="getControlResultsDownloadLink()" download>{{
                  getControlResultByGroupId(2)
                }}</a>
              </vs-td>
              <vs-td>{{ controlGroupUsersCountSplitCheck(2) }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-card>
      <br />
      <vs-card v-if="isControlGroupSplitCheck">
        <div slot="header">
          <h3>{{ $l.get("correlation-view.control-group-intersection") }}</h3>
        </div>
        <vs-table v-if="data" :data="[1]">
          <template slot="thead">
            <vs-th>{{ $l.get("correlation-view.related-snps") }}</vs-th>
            <vs-th>
              {{
                $l.get("correlation-view.control-group-intersection-percent")
              }}
            </vs-th>
          </template>
          <template>
            <vs-tr>
              <vs-td>
                <a :href="getControlResultsDownloadLink()" download>{{
                  getControlResultByGroupId(0)
                }}</a>
              </vs-td>
              <vs-td>{{ controlGroupIntersectionPercent }}%</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-card>
      <br />
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("correlation-view.groups-header") }}</h3>
        </div>
        <vs-table v-if="data" :data="data.groups">
          <template slot="thead">
            <vs-th>{{ $l.get("dictionary.specificity") }}</vs-th>
            <vs-th>{{ $l.get("correlation-view.groups-table-name") }}</vs-th>
            <vs-th>{{ $l.get("correlation-view.related-snps") }}</vs-th>
            <vs-th>
              {{ $l.get("correlation-view.groups-table-users-count") }}
            </vs-th>
            <vs-th>
              {{ $l.get("correlation-view.groups-table-users-view") }}
            </vs-th>
            <vs-th>
              {{ $l.get("correlation-view.groups-table-users-add") }}
            </vs-th>
            <vs-th>{{ $l.get("dictionary.go-up") }}</vs-th>
            <vs-th>{{ $l.get("dictionary.go-down") }}</vs-th>
            <vs-th>{{ $l.get("dictionary.edit") }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>{{ tr.specificity }}</vs-td>
              <vs-td>{{ tr.name }}</vs-td>
              <vs-td>
                <a :href="getGroupResultsDownloadLink(tr)" download>{{
                  getGroupResultsCount(tr)
                }}</a>
              </vs-td>
              <vs-td>{{ tr.users.length }}</vs-td>
              <vs-td>
                <vs-button
                  color="primary"
                  type="border"
                  icon="icon-users"
                  icon-pack="feather"
                  @click="onGroupViewUsersClick(tr)"
                ></vs-button>
              </vs-td>
              <vs-td>
                <vs-button
                  color="primary"
                  type="border"
                  icon="icon-user-plus"
                  icon-pack="feather"
                  @click="onGroupAddUserClick(tr)"
                ></vs-button>
              </vs-td>
              <vs-td>
                <vs-button
                  :disabled="indextr == 0"
                  color="primary"
                  type="border"
                  icon="icon-arrow-up"
                  icon-pack="feather"
                  @click="onGroupUpClick(indextr)"
                ></vs-button>
              </vs-td>
              <vs-td>
                <vs-button
                  :disabled="indextr == data.length - 1"
                  color="primary"
                  type="border"
                  icon="icon-arrow-down"
                  icon-pack="feather"
                  @click="onGroupDownClick(indextr)"
                ></vs-button>
              </vs-td>
              <vs-td>
                <vs-button
                  color="primary"
                  type="border"
                  icon="edit"
                  @click="onEditGroupClick(tr)"
                ></vs-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-divider />
        <div class="expanded">
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-offset="3"
              vs-w="6"
            >
              <vs-button
                color="primary"
                type="filled"
                @click="onAddGroupClick"
                >{{ $l.get("dictionary.add") }}</vs-button
              >
            </vs-col>
          </vs-row>
        </div>
      </vs-card>

      <br />

      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("correlation-view.analysis") }}</h3>
        </div>
        <vs-table v-if="data" :data="data.usersReports">
          <template slot="thead">
            <vs-th>{{ $l.get("dictionary.name") }}</vs-th>
            <vs-th>{{ $l.get("correlation-view.control-group") }}</vs-th>
            <vs-th v-for="(group, idx) in data.groups" :key="idx">
              {{ group.name }}
            </vs-th>
            <vs-th>{{ $l.get("dictionary.process") }}</vs-th>
          </template>

          <template>
            <vs-tr :key="indextr" v-for="(tr, indextr) in data.usersReports">
              <vs-td>{{ tr.user.name }}</vs-td>
              <vs-td>{{ getUserReportControlResult(tr) }}</vs-td>
              <vs-td v-for="(group, idx) in data.groups" :key="idx">
                {{ getUserReportGroupResult(tr, group) }}
              </vs-td>
              <vs-td>
                <vs-button
                  color="primary"
                  type="border"
                  icon-pack="feather"
                  icon="icon-cloud-lightning"
                  :disabled="!tr.canProcess || isProcessing"
                  @click="onProcessUserReportClick(tr)"
                ></vs-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-divider />
        <div class="expanded">
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-offset="3"
              vs-w="6"
            >
              <vs-button
                color="primary"
                type="filled"
                @click="onAddUserReportClick"
                >{{ $l.get("dictionary.add") }}</vs-button
              >
            </vs-col>
          </vs-row>
        </div>
        <br />
        <div class="expanded">
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-offset="3"
              vs-w="6"
            >
              <vs-button
                color="primary"
                type="border"
                icon-pack="feather"
                icon="icon-cloud-lightning"
                @click="onProcessUserReportAllClick"
                >{{ $l.get("dictionary.process-all") }}</vs-button
              >
            </vs-col>
          </vs-row>
        </div>
      </vs-card>
    </div>

    <vs-prompt
      @cancel="onCloseGroupPrompt"
      @accept="onSubmitGroup"
      @close="onCloseGroupPrompt"
      :active.sync="isGroupPromptOpen"
      :title="$l.get('correlation-view.new-group-header')"
    >
      <div class="expanded">
        <vs-input
          :label-placeholder="$l.get('correlation-view.groups-table-name')"
          v-model="groupPromptName"
        />
      </div>
    </vs-prompt>

    <vs-prompt
      @close="onCloseGroupUserPrompt"
      :buttons-hidden="true"
      :active.sync="isGroupUserPromptOpen"
      :title="$l.get('correlation-view.new-group-user-header')"
    >
      <Loader v-if="isGroupUserSearching" />
      <div v-else-if="isGroupUserPromptOpen">
        <div class="expanded">
          <vs-input
            :label-placeholder="$l.get('dictionary.name')"
            v-model="groupUserPromptName"
            ref="groupUserNameInput"
          />
        </div>
        <div v-if="usersSearch && usersSearch.length > 0">
          <vs-divider />
          <vs-list>
            <vs-list-item
              v-for="(user, idx) in usersSearch"
              :key="idx"
              :title="user.name"
            >
              <feather-icon
                v-if="isUserInGroup(user)"
                icon="CheckIcon"
                svgClasses="text-success"
              />
              <feather-icon
                v-else-if="isUserInOtherGroup(user)"
                icon="LinkIcon"
                svgClasses="text-warning"
              />
              <vs-button
                v-else
                color="primary"
                type="border"
                icon="add"
                @click="onAddUserInGroup(user)"
              ></vs-button>
            </vs-list-item>
          </vs-list>
        </div>
      </div>
    </vs-prompt>

    <vs-prompt
      @close="onGroupViewUsersClose"
      :buttons-hidden="true"
      :active.sync="isGroupsUsersViewOpen"
      :title="$l.get('correlation-view.view-group-users-header')"
    >
      <div style="overflow-y: scroll; height: 300px">
        <vs-list>
          <vs-list-item
            v-for="(userGroup, idx) in this.groupsUsersView"
            :key="idx"
            :title="userGroup.user.name"
          >
            <vs-button
              color="danger"
              type="border"
              icon="icon-trash"
              icon-pack="feather"
              @click="onDelUserFromGroup(userGroup)"
            ></vs-button>
          </vs-list-item>
        </vs-list>
      </div>
    </vs-prompt>

    <!--
    <vs-prompt
      @close="onCloseAddUserReportPrompt"
      :buttons-hidden="true"
      :active.sync="isUserReportAddPromptOpen"
      :title="$l.get('correlation-view.new-group-user-header')"
    >
      <Loader v-if="isUserReportAddSearching" />
      <div v-else-if="isUserReportAddPromptOpen">
        <div class="expanded">
          <vs-input
            :label-placeholder="$l.get('dictionary.name')"
            v-model="userReportAddPromptName"
            ref="userReportAddNameInput"
          />
        </div>
        <div v-if="usersSearch && usersSearch.length > 0">
          <vs-divider />
          <vs-list>
            <vs-list-item
              v-for="(user, idx) in usersSearch"
              :key="idx"
              :title="user.name"
            >
              <feather-icon
                v-if="isUserReportInCorrelation(user)"
                icon="CheckIcon"
                svgClasses="text-success"
              />
              <vs-button
                v-else
                color="primary"
                type="border"
                icon="add"
                @click="onAddUserReportResearch(user)"
              ></vs-button>
            </vs-list-item>
          </vs-list>
        </div>
      </div>
    </vs-prompt>
    -->
    <UserFindPrompt
      :isOpen.sync="isUserReportAddPromptOpen"
      :onClose="onAddUserReportResearch"
    />
  </div>
</template>

<script>
import SelectUserReportProcessAllInteractor from "@/business/correlation-view/select-user-report-process-all";
import SelectCorrelationEditInteractor from "@/business/correlation-view/select-correlation-edit";
import SelectUserReportProcessInteractor from "@/business/correlation-view/select-user-report-process";
import SubmitNewUserReportInteractor from "@/business/correlation-view/submit-new-user-report";
import SelectGenerateModelInteractor from "@/business/correlation-view/select-generate-model";
import SelectDeleteGroupUserInteractor from "@/business/correlation-view/select-delete-group-user";
import SubmitNewGroupUserInteractor from "@/business/correlation-view/submit-new-group-user";
import FindUserInteractor from "@/business/correlation-view/find-user";
import SubmitSwapGroupsInteractor from "@/business/correlation-view/submit-swap-groups";
import SubmitEditGroupInteractor from "@/business/correlation-view/submit-edit-group";
import SubmitNewGroupInteractor from "@/business/correlation-view/submit-new-group";
import InitCorrelationViewInteractor from "@/business/correlation-view/init-correlation-view";
import CorrelationViewScreenController from "@/adapters/controllers/screen-correlation-view";
import Loader from "@/application/components/Loader";
import UserFindPrompt from "@/application/components/UserFindPrompt";

export default {
  screen_name: "correlation-view",
  data() {
    return {
      controller: null,
      interactors: {
        selectUserReportProcessAll: null,
        selectCorrelationEdit: null,
        selectUserReportProcess: null,
        submitNewUserReport: null,
        selectGenerateModel: null,
        selectDeleteGroupUser: null,
        submitNewGroupUser: null,
        findUser: null,
        submitSwapGroups: null,
        submitEditGroup: null,
        submitNewGroup: null,
        initCorrelationView: null
      },
      data: null,
      isLoading: false,

      editingGroup: null,
      isGroupNameInvalid: false,
      isGroupPromptOpen: false,
      groupPromptName: "",

      groupAddUser: null,
      userSearchTimer: null,
      isGroupUserNameInvalid: false,
      isGroupUserSearching: false,
      isGroupUserPromptOpen: false,
      groupUserPromptName: "",
      usersSearch: [],

      isGroupsUsersViewOpen: false,
      groupsUsersView: null,

      isUserReportAddPromptOpen: false,
      isUserReportAddSearching: false,
      userReportAddPromptName: ""
    };
  },
  components: { Loader, UserFindPrompt },
  beforeMount() {
    this.controller = this.$injector.get(CorrelationViewScreenController);

    //{ INTERACTORS
    this.interactors.initCorrelationView = this.$injector.get(
      InitCorrelationViewInteractor
    );
    this.interactors.submitNewGroup = this.$injector.get(
      SubmitNewGroupInteractor
    );
    this.interactors.submitEditGroup = this.$injector.get(
      SubmitEditGroupInteractor
    );
    this.interactors.submitSwapGroups = this.$injector.get(
      SubmitSwapGroupsInteractor
    );
    this.interactors.findUser = this.$injector.get(FindUserInteractor);
    this.interactors.submitNewGroupUser = this.$injector.get(
      SubmitNewGroupUserInteractor
    );
    this.interactors.selectDeleteGroupUser = this.$injector.get(
      SelectDeleteGroupUserInteractor
    );
    this.interactors.selectGenerateModel = this.$injector.get(
      SelectGenerateModelInteractor
    );
    this.interactors.submitNewUserReport = this.$injector.get(
      SubmitNewUserReportInteractor
    );
    this.interactors.selectUserReportProcess = this.$injector.get(
      SelectUserReportProcessInteractor
    );
    this.interactors.selectCorrelationEdit = this.$injector.get(
      SelectCorrelationEditInteractor
    );
    this.interactors.selectUserReportProcessAll = this.$injector.get(
      SelectUserReportProcessAllInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
    this.controller.reload = this.reload;
    this.controller.setAsSearchingUser = this.setAsSearchingUser;
    this.controller.setUsersFound = this.setUsersFound;
  },
  mounted() {
    const id = this.$route.params.id;
    this.interactors.initCorrelationView.handle(id);
  },
  computed: {
    isControlGroupDefault() {
      return (
        this.data &&
        this.data.correlation &&
        this.data.correlation.controlGroupType == 1
      );
    },
    isControlGroupSplitCheck() {
      return (
        this.data &&
        this.data.correlation &&
        this.data.correlation.controlGroupType == 2
      );
    },
    controlGroupUsersCount() {
      var total = 0;
      for (const group of this.data.groups) {
        total += group.users.length;
      }
      return total;
    },
    controlGroupIntersectionPercent() {
      const g0 = this.getControlResultByGroupId(0);
      const g1 = this.getControlResultByGroupId(1);
      const g2 = this.getControlResultByGroupId(2);

      if (g1 == 0 || g2 == 0) return 0;

      if (g1 < g2) {
        return Math.round((100.0 * g0) / g1);
      } else {
        return Math.round((100.0 * g0) / g2);
      }
    },
    isProcessing() {
      return (
        this.data &&
        this.data.correlation &&
        this.data.correlation.tasksCount > 0
      );
    }
  },
  watch: {
    groupUserPromptName(search) {
      this.onUserSearchChange(search);
    },
    userReportAddPromptName(search) {
      this.onUserSearchChange(search);
    }
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    controlGroupUsersCountSplitCheck(index) {
      const total = this.controlGroupUsersCount;
      const half = Math.floor(total / 2);
      if (index == 1) return half + (total % 2);
      else return half;
    },
    setAsSearchingUser() {
      this.isGroupUserSearching = true;
    },
    setUsersFound(users) {
      // console.log(users);
      this.usersSearch = users;
      this.isGroupUserSearching = false;
      const _ = this;
      _.$nextTick(() => {
        var input =
          _.$refs.groupUserNameInput || _.$refs.userReportAddPromptName;
        if (input) {
          input.$el.querySelector("input").focus();
        }
      });
    },
    reload() {
      const id = this.$route.params.id;
      this.interactors.initCorrelationView.handle(id);
    },
    onEditGroupClick(group) {
      this.editingGroup = group;
      this.groupPromptName = group.name;
      this.isGroupPromptOpen = true;
    },
    onAddGroupClick() {
      this.isGroupPromptOpen = true;
    },
    onCloseGroupPrompt() {
      this.editingGroup = null;
      this.isGroupPromptOpen = false;
    },
    onGroupUpClick(index) {
      if (index == 0) return;
      const group1 = this.data.groups[index];
      const group2 = this.data.groups[index - 1];
      this.interactors.submitSwapGroups.handle(group1, group2);
    },
    onGroupDownClick(index) {
      if (index == this.data.groups.length - 1) return;
      const group1 = this.data.groups[index];
      const group2 = this.data.groups[index + 1];
      this.interactors.submitSwapGroups.handle(group1, group2);
    },
    onSubmitGroup() {
      this.isGroupNameInvalid = !this.groupPromptName;
      if (this.isGroupNameInvalid) return;
      const id = this.$route.params.id;
      if (this.editingGroup) {
        const group = this.editingGroup;
        group.name = this.groupPromptName;
        this.interactors.submitEditGroup.handle(this.editingGroup);
        this.editingGroup = null;
      } else {
        const group = {
          correlation: id,
          name: this.groupPromptName,
          specificity: this.data.groups.length + 1
        };
        this.interactors.submitNewGroup.handle(group);
      }
      this.groupPromptName = "";
    },
    onGroupViewUsersClick(group) {
      this.groupsUsersView = group.users;
      this.isGroupsUsersViewOpen = true;
    },
    onGroupViewUsersClose() {
      this.isGroupsUsersViewOpen = false;
    },
    onGroupAddUserClick(group) {
      this.groupAddUser = group;
      this.isGroupUserPromptOpen = true;
      // this.$refs.groupUserNameInput.$el.focus();
    },
    onCloseGroupUserPrompt() {
      this.isGroupUserPromptOpen = false;
    },
    onUserSearchChange(search) {
      if (this.userSearchTimer) {
        clearTimeout(this.userSearchTimer);
      }
      const _ = this;
      this.userSearchTimer = setTimeout(() => {
        _.userSearchTimer = null;
        _.interactors.findUser.handle(search);
      }, 500);
    },
    isUserInGroup(user) {
      return (
        this.groupAddUser &&
        this.groupAddUser.users.find(e => e.user.id == user.id) != undefined
      );
    },
    isUserInOtherGroup(user) {
      for (const group of this.data.groups) {
        if (group.id != this.groupAddUser.id) {
          const userFound = group.users.find(e => e.user.id == user.id);
          if (userFound != undefined) {
            return true;
          }
        }
      }
      return false;
    },
    onAddUserInGroup(user) {
      const instance = {
        userId: user.id,
        group: this.groupAddUser.id
      };
      this.onCloseGroupUserPrompt();
      this.interactors.submitNewGroupUser.handle(instance);
    },
    onDelUserFromGroup(groupUser) {
      this.onGroupViewUsersClose();
      this.interactors.selectDeleteGroupUser.handle(groupUser);
    },
    onGenerateModelClick() {
      const correlation = this.data.correlation;
      this.interactors.selectGenerateModel.handle(correlation);
    },
    getGroupResultsCount(group) {
      var result = "-";
      for (const groupResult of this.data.results.groups) {
        if (groupResult.id == group.id) {
          return groupResult.count.toString();
        }
      }
      return result;
    },
    getControlResultsDownloadLink() {
      const gateway = this.$injector.get("CorrelationGateway");
      const id = this.$route.params.id;

      return `${gateway.URL}/${id}/download`;
    },
    getGroupResultsDownloadLink(group) {
      const gateway = this.$injector.get("CorrelationGroupGateway");
      const id = group.id;

      return `${gateway.URL}/${id}/download`;
    },
    onCloseAddUserReportPrompt() {
      this.isUserReportAddPromptOpen = false;
    },
    onAddUserReportClick() {
      this.isUserReportAddPromptOpen = true;
    },
    isUserReportInCorrelation(user) {
      if (this.data && this.data.usersReports) {
        for (const userReport of this.data.usersReports) {
          if (userReport.id == user.id) {
            return true;
          }
        }
      }
      return false;
    },
    onAddUserReportResearch(user) {
      this.onCloseAddUserReportPrompt();
      if (!user) return;
      const instance = {
        userId: user.id,
        correlation: this.data.correlation.id
      };
      this.interactors.submitNewUserReport.handle(instance);
    },
    onProcessUserReportClick(userReport) {
      this.interactors.selectUserReportProcess.handle(userReport);
    },
    getUserReportControlResult(userReport) {
      const data = userReport.resultData;
      if (data && data.control) {
        return `${data.control}%`;
      } else {
        if (data && data.groups) {
          return "0%";
        }
        return "-";
      }
    },
    getUserReportGroupResult(userReport, group) {
      const data = userReport.resultData;
      if (data && data.groups) {
        for (const groupResult of data.groups) {
          if (group.id == groupResult.id) {
            return `${groupResult.result}%`;
          }
        }
      }
      return "-";
    },
    onEditClick() {
      this.interactors.selectCorrelationEdit.handle(this.data.correlation);
    },
    getControlResultByGroupId(groupId) {
      if (
        !this.data ||
        !this.data.results ||
        !this.data.results.controlResultsByGroups
      )
        return 0;
      const controlGroupsResults = this.data.results.controlResultsByGroups;
      for (var result of controlGroupsResults) {
        if (result.id == groupId) {
          return result.count;
        }
      }
      // console.log("not found");
      return 0;
    },
    onProcessUserReportAllClick() {
      this.interactors.selectUserReportProcessAll.handle();
    }
  }
};
</script>

<style lang="scss">
.expanded .vs-con-input-label {
  width: 100% !important;
  margin-top: 25px;
}

.expanded .vs-button {
  width: 100% !important;
}
</style>
