<template>
  <div class="user-find-prompt">
    <vs-prompt
      @close="onClose(null)"
      :buttons-hidden="true"
      :active.sync="isOpen"
      :title="$l.get('dictionary.find-user')"
    >
      <Loader v-if="isSearching" />
      <div v-else-if="isOpen">
        <div class="expanded">
          <vs-input
            :label-placeholder="$l.get('dictionary.name')"
            v-model="name"
            ref="nameInput"
          />
        </div>
        <div v-if="usersSearch && usersSearch.length > 0">
          <vs-divider />
          <vs-list>
            <vs-list-item
              v-for="(user, idx) in usersSearch"
              :key="idx"
              :title="user.name"
            >
              <vs-button
                color="primary"
                type="border"
                icon="add"
                @click="onClose(user)"
              ></vs-button>
            </vs-list-item>
          </vs-list>
        </div>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import { IUserGateway, ISessionGateway } from "@/adapters/gateways/users";
import Loader from "@/application/components/Loader";
export default {
  name: "UserPrompt",
  props: ["onClose", "isOpen"],
  components: { Loader },
  data() {
    return {
      user: null,
      name: "",
      usersSearch: [],
      isSearching: false,
      userSearchTimer: null,
      session: null,
      gateways: {
        users: null,
        session: null
      }
    };
  },
  beforeMount() {
    this.gateways.users = this.$injector.get("UserGateway");
    this.gateways.session = this.$injector.get("SessionGateway");
    this.session = this.gateways.session.get();
    // console.log(this.gateways);
  },
  watch: {
    name(search) {
      this.onUserSearchChange(search);
    }
  },
  methods: {
    onUserSearchChange(search) {
      if (this.userSearchTimer) {
        clearTimeout(this.userSearchTimer);
      }
      const _ = this;
      this.userSearchTimer = setTimeout(async () => {
        _.userSearchTimer = null;
        _.isSearching = true;
        const usersPage = await _.gateways.users.list(
          _.session.tokens,
          1, // page
          search, // search
          "name", // order
          5 // page size
        );
        _.usersSearch = usersPage.results;
        _.isSearching = false;
      }, 500);
    }
  }
};
</script>

<style lang="scss">
.expanded .vs-con-input-label {
  width: 100% !important;
  margin-top: 25px;
}

.expanded .vs-button {
  width: 100% !important;
}
</style>
